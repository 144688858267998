import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest'
import { HTTPMethod } from '@/core/infrastructure/api/APIClient'

export namespace RegistryFilesAPI {
  export class GetFileNameById implements APIRequest<string> {
    response: string;
    path = 'registryservice/files/';
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data;
    hideNotification = true;

    constructor (id: Number) {
      this.path += id
    }
  }
}
